.ribbon {

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
.ribbon-right {
    left: 28px;
    span {
        transform: translateX(-50%) translateY(-40%) rotate(-90deg);
        top: 40%;
        left: 50%;
        font-size: 12px;
    }
}
.ribbon-left{
    right: 25px;
    span{
        transform: translateX(-50%) translateY(-40%) rotate(-90deg);
        top: 40%;
        left: 50%;
        font-size: 12px;

    }
}
