
.product-card {
    .h-80px{
        height: 80px !important;
    }
    .images-wrapper {
        overflow: hidden;
        .secondary-image {
            transform:scale(1.3) ;
            opacity: 0;
            transition: all .3s ease-out;
        }
    }

    .images-wrapper:hover {
        .secondary-image {
            opacity: 1;
            transform:scale(1) ;
        }
        .primary-image {
            transition: all .2s ease;
            opacity: 0;
        }
    }
}
