@import "card";

#paymentContainer{
    #paymentMethods{
        .accordion-item{
            border-radius: 10px !important;
            .accordion-button{
                border-radius: 10px !important;
                .fa-minus{
                    display: none;
                    border:  1px solid black;
                    color: black;
                    border-radius: 3px;
                    padding: 2px;
                    font-size: 13px;
                }
                .fa-plus{
                    display: block;
                    border:  1px solid $muted-md;
                    color: $muted-md;
                    border-radius: 3px;
                    padding: 2px;
                    font-size: 13px;
                }


            }
            .accordion-button:not(.collapsed){
                .fa-minus{
                    display: block;
                }
                .fa-plus{
                    display: none;
                }
            }
            .accordion-button::after{
                content: '';
                background-image: none;
                border: 5px solid #A9A9A9;
                background-color: white;
                width: 20px;
                height: 20px;
                border-radius: 50%;
            }
        }

        .plusButton::after{
            display: none;
        }
        .minusButton::after{
            display: none;
        }
        .accordion-button:not(.collapsed)::after {
            border: 5px solid $base-color-1;
        }
    }

    #newMe {
        .accordion-item{
            background: linear-gradient(0deg, rgba(255, 0, 79, 0.1), rgba(255, 0, 79, 0.1)),
            linear-gradient(0deg, #FFFFFF, #FFFFFF);
        }
    }

    #billingSummary{
        .subtotal{
            border-bottom: 1px solid $muted-lg;
        }
    }


}
