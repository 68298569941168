.completedOnButtons{

    button{
        color:  white;
        &.notActive{
            background-color: white;
            color: black;
            border: 1px solid black;
        }
    }
    .red{
        background-color: #ff004f;
        border: 1px solid #ff004f;
    }
    .green{
        background-color: #00BA00 ;
        border: 1px solid #00BA00;
    }
    .yellow{
        background-color: #FD8D14 ;
        border: 1px solid #FD8D14;
    }
}
