#estimateShoppingModal{
    svg{
        display: none;
    }
    .custom-success-alert{
        background: rgba(255, 0, 79, 0.13);
        color: #198754 !important;
        border: 1px solid #ff004f;
        #success-svg-icon{
            display: block !important;
            fill: #198754;
        }

    }
    .custom-unsuccess-alert{
        background: #ff4848;
        color: white !important;
        border: red;
        #unsuccess-svg-icon{
            display: block !important;
            fill: white;
        }
    }
}
