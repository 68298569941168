#checkbox {
    display: none;
}

.toggle {
    position: relative;
    width: 22px;
    height: 22px;
    cursor: pointer;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
    transition-duration: .2s;
}

.bars {
    width: 100%;
    height: 3px;
    background-color: $base-color-1;
    border-radius: 5px;
    transition-duration: .3s;
}

#checkbox:checked + .toggle .bars {
    margin-left: 7px;
}

#checkbox:checked + .toggle #bar2 {
    transform: rotate(-45deg);
    margin-left: 0;
    transform-origin: center;
    transition-duration: .3s;
}

#checkbox:checked + .toggle #bar1 {
    transform: rotate(45deg);
    transition-duration: .3s;
    transform-origin: left center;

}

#checkbox:checked + .toggle #bar3 {
    transform: rotate(-45deg);
    transition-duration: .3s;
    transform-origin: left center;
    display: none;
}
