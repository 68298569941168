.recipeCard{
    margin-top: 3rem;}
@media (max-width: 992px) {
    .recipeCard{
        &:not(:first-child){
            margin-top: 9rem;
        }
        &:first-child{
            margin-top: 5rem;
        }
        .imageCol,{
            transform: translateY(-80px);
        }
        .detailsCol{
            transform: translateY(-35px);
        }

    }

}
