
.offcanvas-shoping-cart {
    width: 100% !important;

}
.input-number{
    transform: translateY(-25px);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

@media (min-width: 768px) {
    #shop-cart-offcanvas-body {
        .overlay-image {
            width: 140px !important;
            height: 140px !important;
        }

    }

    .offcanvas-shoping-cart {
        width: 640px !important;
    }
}


#shop-cart-offcanvas-body {
    .overlay-image {
        width: 120px;
        height: 120px;
        left: 4px;
        top:16px;
        transition: all .2s ease;
        opacity: 0;
        background-color: black;

        &:hover {
            opacity: .3;
        }
    }
    .overlay-image-rtl {
        left: unset !important;
        right: 4px !important;
        &:hover {
            opacity: .3;
        }
    }

    #products-list {
        overflow-y: scroll;
        max-height: 400px;
    }

    #products-list::-webkit-scrollbar {
        width: 10px;
    }

    #products-list::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey;
        border-radius: 10px;
    }

    #products-list::-webkit-scrollbar-thumb {
        background: #ff004f;
        border-radius: 10px;
    }
}
