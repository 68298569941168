.product-show {
    .image-zoom {
        background-image: url('https://picsum.photos/2000/2300');
        background-size: cover;
        z-index: 1000 !important;
        background-position: center;
        background-repeat: no-repeat;
        transition: transform 0.2s;
    }

    .w-76px {
        width: 76px;

    }

    .h-76px {
        height: 76px;
    }
    @media (max-width: 500px) {
        .h-76px {
            height: 50px !important;
        }
    }

    .cursor-zoom-in {
        cursor: zoom-in !important;
    }

    .productGallarySwiper {
        .swiper-slide-thumb-active {
            border: 2px solid black;
        }
    }

    #productGallaryModal {
        .w-40px {
            width: 40px;
        }

        .h-40px {
            height: 40px;
        }

        .w-50px {
            width: 50px;
        }

        .h-50px {
            height: 50px;
        }

        .swiper-slide-thumb-active {
            border: 2px solid black;
        }

        .scale-hover {
            transition: transform 0.2s ease;

        }

        .scale-hover:hover {
            transform: scale(1.1);
        }

        .swiper-button-next:after, .swiper-button-prev:after {
            content: unset !important;
        }

        .swiper-button-next, .swiper-button-prev {
            svg {
                width: 25px !important;
                height: 25px !important;
            }
        }


        .cursor-zoom-in {
            cursor: zoom-in;
        }

        .cursor-zoom-out {
            cursor: zoom-out;
        }
    }

    .accordion-header {

        transition: all .2s ease;

        &:hover {
            .accordion-button {
                background-color: #e0e0e0;
            }

            background-color: #e0e0e0;
        }
    }
}

