.buttons__burger {
    --size: 3rem;
    --clr: #000;
    width: var(--size);
    height: calc(0.7 * var(--size));
    cursor: pointer;
    position: relative;
    display: block;
    z-index: 1046;
    position:absolute;
}

.buttons__burger #burger {
    display: none;
    width: 100%;
    height: 100%;
}

.buttons__burger span {
    display: block;
    position: absolute;
    width: 100%;
    border-radius: 0.5rem;
    border: 3px solid var(--clr);
    background-color: var(--clr);
    transition: 0.15s ease-in-out;
}

.buttons__burger span:nth-of-type(1) {
    top: 0;
    right: 0;
    transform-origin: right center;
}

.buttons__burger span:nth-of-type(2) {
    top: 50%;
    transform: translateY(-50%);
}

.buttons__burger span:nth-of-type(3) {
    top: 100%;
    right: 0;
    transform-origin: right center;
    transform: translateY(-100%);
}

.buttons__burger #burger:checked ~ span:nth-of-type(1) {
    transform: translateY(-30%) rotate(40deg);
    width: 50%;
    top: 50%;
}

.buttons__burger #burger:checked ~ span:nth-of-type(3) {
    transform: translateY(-70%) rotate(-40deg);
    width: 50%;
    top: 50%;
}
