.free-programm-card{
    .overlay{
        .mask{
            background-color: rgba(0,0,0,0);
            & *{
                opacity: 0;
            }
        }


    }
    &:hover{
        .overlay{
            transition: all .15s ease;
            transform: translateY(-4px);
            .mask{
                transition: all .5s ease;
                background-color: rgba(0,0,0,.3);
                & *{
                    transition: all .5s ease;
                    opacity: 1;
                }
            }
        }
    }
}
