.why-new-me-container{
    .why-new-me-card .bg-1{
        background: linear-gradient(0deg, #F2DDDD, #F2DDDD),
        linear-gradient(77.42deg, rgba(255, 255, 255, 0) 9.74%, rgba(255, 255, 255, 0.4) 24.69%, rgba(255, 255, 255, 0.354161) 49.18%, rgba(255, 255, 255, 0) 70.81%);

    }
    .why-new-me-card .bg-2{
        background: linear-gradient(0deg, #EBDCE5, #EBDCE5),
        linear-gradient(77.42deg, rgba(255, 255, 255, 0) 9.74%, rgba(255, 255, 255, 0.4) 24.69%, rgba(255, 255, 255, 0.354161) 49.18%, rgba(255, 255, 255, 0) 70.81%);

    }
    .paymentOptionCard{
        transition: all .1s ease;
        &:hover{
            transform: scale(1.5);
        }
    }
    .ratingCard{
        background-color: white;
        transition: all .1s ease;
        &:hover{
            background-color:#f7f7f7;
        }
    }
}
