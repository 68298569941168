.actions {
    .action-icon-box {
        width: 32px;
        height: 32px;
        svg {
            fill: black;
            width: 24px;
            height: 24px;
        }
        &:hover {
            background-color: #f6f6f6;
        }

    }
}
