.video-play-button:after {
    content: "";
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 150px;
    height: 150px;
    background: $bg-base;
    opacity: .9;
    border-radius: 50%;
    transition: all 200ms;
}
.video-play-button:before {
    content: "";
    position: absolute;
    z-index: 0;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 150px;
    height: 150px;
    background: $bg-base;
    border-radius: 50%;
    animation: pulse-border 1500ms ease-out infinite;
}

@keyframes pulse-border {
    0% {
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
        opacity: .8;
    }
    100% {
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
        opacity: 0;
    }
}

@media (max-width: 992px) {
    .video-play-button:after {
        width: 90px;
        height: 90px;
    }
    .video-play-button:before {
        width: 90px;
        height: 90px;
    }
}

