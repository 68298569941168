    .list{
        .item{
            &:hover{
                background-color: white;
                color: $base-color-1;
            }
        }

    }
    .border-bottom-dashed{
        border-bottom: 1px dashed #9A9A9A ;
    }
