

@media (max-width: 600px){
    .shopping-cart-btn{
        transform: translateY(16px) !important;
    }
}
.custom-header {
.shopping-cart-btn{
    transform: translateY(19px);
}

    .product-counter {
        width: 19px;
        height: 19px;
        transform: translate(16px,-2px);
    }
    .product-counter-rtl{
        width: 19px;
        height: 19px;
        transform: translate(-16px,-2px);
    }
    background: linear-gradient(0deg, #F8F8F8, #F8F8F8),
    linear-gradient(0deg, rgba(255, 0, 79, 0.2), rgba(255, 0, 79, 0.2));

    #navbarSupportedContent {
        ul {
            li.nav-item {
                a:not(.join-now-btn) {
                    transition: color .3s ease;
                }

                a:hover {
                    color: $base-color-1;
                }

                .active {
                    color: $base-color-1 !important;
                    position: relative;
                }

                .active::before {
                    position: absolute;
                    width: 7px;
                    height: 1px;
                    background-color: $base-color-1;
                    content: '';
                    top: 50%;
                    left: -5px;
                }
            }
        }
    }

    button.navbar-toggler {
        .bi-x-lg {
            font-weight: 800;
            font-size: 1.75rem;
        }
    }

    a#signin {
        &:hover {
            border: 1px solid white !important;
            color: white !important;
        }
    }
}


@media (max-width: 600px) {
    .custom-header {
        .navbar-brand {
            img {
                width: 50px !important;
                height: 50px !important;
            }
        }
    }
}

@media (max-width: 1200px) {

    .navbar {
        #wrapper {
            width: 100%;
        }
    }


}
