footer {
    background: linear-gradient(0deg, rgba(255, 0, 79, 0.5) -131.23%, rgba(255, 0, 79, 0) 100%);

    .container-fluid {
        border-top: 1px solid #F501001A;
        border-bottom: 1px solid #F501001A;
    }

    #subscribeForm {
        .btn-outline-secondary {
            color: white;
            right: 10px;
            bottom: 7px;
        }
        #subscribe{
            border: none !important;

        }


        #subscribe::placeholder  {
            color: black;
            opacity: 1;
            font-weight: bold;
            font-size: 1rem;
        }
    }

    .top-section{
        background: linear-gradient(180deg, rgba(255, 0, 79, 0.1) 0%, rgba(255, 0, 79, 0.5) 100%),
        linear-gradient(0deg, #FFFFFF, #FFFFFF);

    }
}


